export const emailPass = (email) => {
    if(email.indexOf('@')==-1){return false;}
    if(email.indexOf('@') > (email.length -3)){return false;}
    if(email.indexOf('@') == 0){return false;}
    return (email.indexOf('.')!=(-1));
 };
 export const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
      window.location.hash = `#${id}`;
    }
  };