import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function ImageDownloaderRateUs(props) {
    const headers = {
        "ar": ["تم تحديث امتداد تنزيل الصور الخاص بك إلى أحدث إصدار"],
        "am": ["የእርስዎ ኤክስቴንሽን የምስል አውርዶ ወደ ቅንብሩ ተሻሽሏል"],
        "bg": ["Вашето разширение за изтегляне на изображения беше актуализирано до най-новата версия"],
        "bn": ["আপনার এক্সটেনশন ইমেজ ডাউনলোডারটি সর্বশেষ সংস্করণে আপডেট করা হয়েছে"],
        "ca": ["La teva extensió de descarregador d'imatges s'ha actualitzat a l'última versió"],
        "cs": ["Vaše rozšíření pro stahování obrázků bylo aktualizováno na nejnovější verzi"],
        "da": ["Din udvidelse billeddownloader blev opdateret til den nyeste version"],
        "de": ["Ihre Erweiterung Bild-Downloader wurde auf die neueste Version aktualisiert"],
        "el": ["Η επέκταση λήψης εικόνας ενημερώθηκε στην πιο πρόσφατη έκδοση"],
        "en": ["Your extension Image downloader was updated to the latest version"],
        "es": ["Su extensión de descargador de imágenes se actualizó a la última versión"],
        "et": ["Teie pildiallalaadija laiend on värskendatud uusimale versioonile"],
        "fa": ["افزونه دانلود کننده تصویر شما به آخرین نسخه به‌روزرسانی شد"],
        "fi": ["Laajennuksesi kuvien latausohjelma päivitettiin uusimpaan versioon"],
        "fil": ["Ang iyong extension Image downloader ay na-update sa pinakabagong bersyon"],
        "fr": ["Votre extension de téléchargeur d'images a été mise à jour vers la dernière version"],
        "gu": ["તમારું એક્સટેન્શન છબી ડાઉનલોડર નવીનતમ સંસ્કરણમાં અપડેટ થયું"],
        "he": ["ההרחבה שלך להורדת תמונות עודכנה לגרסה האחרונה"],
        "hi": ["आपके एक्सटेंशन इमेज डाउनलोडर को नवीनतम संस्करण में अपडेट किया गया"],
        "hr": ["Vaše proširenje za preuzimanje slika ažurirano je na najnoviju verziju"],
        "hu": ["A képletöltő bővítménye frissült a legújabb verzióra"],
        "id": ["Ekstensi pengunduh gambar Anda diperbarui ke versi terbaru"],
        "it": ["La tua estensione per il download di immagini è stata aggiornata all'ultima versione"],
        "ja": ["拡張機能の画像ダウンローダーが最新バージョンに更新されました"],
        "kn": ["ನಿಮ್ಮ ವಿಸ್ತರಣೆ ಚಿತ್ರ ಡೌನ್‌ಲೋಡರ್ ಅನ್ನು ಇತ್ತೀಚಿನ ಆವೃತ್ತಿಗೆ ನವೀಕರಿಸಲಾಗಿದೆ"],
        "ko": ["귀하의 확장 프로그램 이미지 다운로더가 최신 버전으로 업데이트되었습니다"],
        "lt": ["Jūsų plėtinys vaizdų atsisiuntimo programa atnaujinta į naujausią versiją"],
        "lv": ["Jūsu paplašinājums attēlu lejupielādētājs tika atjaunināts uz jaunāko versiju"],
        "ml": ["നിങ്ങളുടെ എക്സ്റ്റെൻഷൻ ഇമേജ് ഡൗൺലോഡർ ഏറ്റവും പുതിയ പതിപ്പിലേക്ക് അപ്‌ഡേറ്റ് ചെയ്യപ്പെട്ടു"],
        "mr": ["तुमचा विस्तार इमेज डाउनलोडर नवीनतम आवृत्तीत अद्यतनित केला गेला आहे"],
        "ms": ["Sambungan pemuat turun imej anda telah dikemas kini ke versi terkini"],
        "nl": ["Uw extensie afbeeldingsdownloader is bijgewerkt naar de nieuwste versie"],
        "no": ["Utvidelsen din for bildenedlasting ble oppdatert til den nyeste versjonen"],
        "pl": ["Twoje rozszerzenie do pobierania obrazów zostało zaktualizowane do najnowszej wersji"],
        "pt": ["Sua extensão de downloader de imagens foi atualizada para a versão mais recente"],
        "ro": ["Extensia dvs. de descărcare a imaginilor a fost actualizată la cea mai recentă versiune"],
        "ru": ["Ваше расширение для загрузки изображений было обновлено до последней версии"],
        "sk": ["Vaša rozšírenie na sťahovanie obrázkov bolo aktualizované na najnovšiu verziu"],
        "sl": ["Vaša razširitev za prenos slik je bila posodobljena na najnovejšo različico"],
        "sr": ["Ваше проширење за преузимање слика је ажурирано на најновију верзију"],
        "sv": ["Din tilläggsprogram för bildnedladdning har uppdaterats till den senaste versionen"],
        "sw": ["Kiendelezi chako cha kupakua picha kimesasishwa hadi toleo jipya zaidi"],
        "ta": ["உங்கள் நீட்டிப்பு படத்தைப் பதிவிறக்கி சமீபத்திய பதிப்பிற்கு புதுப்பிக்கப்பட்டது"],
        "te": ["మీ విస్తరణ చిత్రం డౌన్‌లోడర్‌ను తాజా వెర్షన్‌కు అప్డేట్ చేయబడింది"],
        "th": ["โปรแกรมเสริมดาวน์โหลดรูปภาพของคุณได้รับการอัปเดตเป็นเวอร์ชันล่าสุด"],
        "tr": ["Uzantınızın görüntü indiricisi en son sürüme güncellendi"],
        "uk": ["Ваше розширення для завантаження зображень було оновлено до останньої версії"],
        "vi": ["Tiện ích mở rộng tải xuống hình ảnh của bạn đã được cập nhật lên phiên bản mới nhất"],
        "zh_CN": ["您的扩展程序图像下载器已更新到最新版本"],
        "zh_TW": ["您的擴充功能圖像下載器已更新到最新版本"],
        "zu": ["Isandiso sakho sokulanda izithombe sibuyekeziwe kuya kunguqulo lwakamuva"]
    };

    const text = {
            "en": {
                "p1": "We work hard to make it the best image download extension. If you are happy with our extension",
                "p2": "please consider",
                "p3": "rating the extension.",
                "p4": "what's new",
                "p5": "We improved and fixed some bugs in the image capture mechanism - it works a bit faster now.",
                "p6": "We improved the download mechanism, request for downloads are now sent in parallel speeding up multi image downloads.",
                "p7": "We made improvements to the user interface - you can now change the theme."
            },
            "ar": {
                "p1": "نحن نعمل جاهدين لجعلها أفضل إضافة لتحميل الصور. إذا كنت سعيدًا بإضافتنا",
                "p2": "يرجى التفكير في",
                "p3": "تقييم الإضافة.",
                "p4": "ما الجديد",
                "p5": "لقد قمنا بتحسين وإصلاح بعض الأخطاء في آلية التقاط الصور - الآن تعمل أسرع قليلاً.",
                "p6": "قمنا بتحسين آلية التحميل، حيث يتم الآن إرسال طلبات التحميل بشكل متوازٍ مما يسرع تحميل الصور المتعددة.",
                "p7": "قمنا بتحسين واجهة المستخدم - يمكنك الآن تغيير السمة."
            },
            "am": {
                "p1": "እኛ በጥንቃቄ ስለምንችል ምርጥ ምስል መጠቀም እትም በማስተካከል እንሰራለን። እንደምትምር እባክህ በገበታው ስለእትም",
                "p2": "እባኮትን እንዴት በእትም",
                "p3": "እባክህ ምርጠኝ በሚከተል።",
                "p4": "ምንነት ነበር",
                "p5": "እኛ እንባላለን ምንጮቹን አማራጭ፣ በአሰፋል ያሳምኑበት ምንጮችን",
                "p6": "ምእቶቻ በአካበባል አቅርቦትን",
                "p7": "ወሰና እጅድ"
            },
            "bg": {
                "p1": "Ние работим усилено, за да я направим най-доброто разширение за изтегляне на изображения. Ако сте доволни от нашето разширение",
                "p2": "моля, обмислете",
                "p3": "оценяването на разширението.",
                "p4": "Какво ново",
                "p5": "Подобрихме и поправихме някои бъгове в механизма за улавяне на изображения – сега работи малко по-бързо.",
                "p6": "Подобрихме механизма за изтегляне, заявките за изтегляне вече се изпращат паралелно, което ускорява многократните изтегляния на изображения.",
                "p7": "Направихме подобрения в потребителския интерфейс – сега можете да променяте темата."
            },
            "bn": {
                "p1": "আমরা এটিকে সেরা ইমেজ ডাউনলোড এক্সটেনশন তৈরি করতে কঠোর পরিশ্রম করি। আপনি যদি আমাদের এক্সটেনশনে খুশি হন",
                "p2": "দয়া করে মনে করুন",
                "p3": "এক্সটেনশনটি রেটিং করা।",
                "p4": "নতুন কি",
                "p5": "আমরা ইমেজ ক্যাপচার প্রক্রিয়াটির কিছু বাগ ঠিক করেছি এবং এটি এখন একটু দ্রুত কাজ করছে।",
                "p6": "আমরা ডাউনলোড প্রক্রিয়া উন্নত করেছি, এখন ডাউনলোডের জন্য অনুরোধগুলি একযোগে পাঠানো হয়, যা একাধিক ছবি ডাউনলোডের গতি বাড়ায়।",
                "p7": "আমরা ব্যবহারকারী ইন্টারফেসে কিছু উন্নতি করেছি – আপনি এখন থিম পরিবর্তন করতে পারেন।"
            },
            "ca": {
                "p1": "Treballam molt per fer-ho la millor extensió de descàrrega d'imatges. Si estàs content amb la nostra extensió",
                "p2": "si us plau, considera",
                "p3": "valorant l'extensió.",
                "p4": "Què hi ha de nou",
                "p5": "Hem millorat i arreglat alguns errors en el mecanisme de captura d'imatges - ara funciona una mica més ràpid.",
                "p6": "Hem millorat el mecanisme de descàrrega, ara les sol·licituds de descàrrega es realitzen de manera paral·lela, accelerant la descàrrega de diverses imatges.",
                "p7": "Hem millorat la interfície d'usuari - ara pots canviar el tema."
            },
            "cs": {
                "p1": "Tvrdě pracujeme na tom, abychom to udělali nejlepší rozšíření pro stahování obrázků. Pokud jste spokojeni s naším rozšířením",
                "p2": "zvažte, prosím,",
                "p3": "ohodnocení rozšíření.",
                "p4": "co je nového",
                "p5": "Vylepšili jsme a opravili některé chyby v mechanismu zachytávání obrázků - nyní funguje o něco rychleji.",
                "p6": "Vylepšili jsme mechanismus stahování, žádosti o stažení jsou nyní odesílány paralelně, což zrychluje stahování více obrázků.",
                "p7": "Vylepšili jsme uživatelské rozhraní - nyní můžete změnit téma."
            },
            "da": {
                "p1": "Vi arbejder hårdt for at gøre det til den bedste billede-download-udvidelse. Hvis du er glad for vores udvidelse",
                "p2": "overvej venligst",
                "p3": "at bedømme udvidelsen.",
                "p4": "Hvad er nyt",
                "p5": "Vi har forbedret og rettet nogle fejl i billedfangstmekanismen - den fungerer nu lidt hurtigere.",
                "p6": "Vi har forbedret downloadmekanismen, anmodninger om downloads sendes nu parallelt, hvilket fremskynder download af flere billeder.",
                "p7": "Vi har lavet forbedringer i brugergrænsefladen - du kan nu ændre temaet."
            },
            "de": {
                "p1": "Wir arbeiten hart daran, es zur besten Bild-Download-Erweiterung zu machen. Wenn Sie mit unserer Erweiterung zufrieden sind",
                "p2": "bitte erwägen Sie",
                "p3": "die Erweiterung zu bewerten.",
                "p4": "Was gibt's Neues",
                "p5": "Wir haben einige Fehler im Bildaufnahme-Mechanismus behoben und verbessert – es funktioniert jetzt etwas schneller.",
                "p6": "Wir haben den Download-Mechanismus verbessert, Anfragen für Downloads werden jetzt parallel gesendet, was das Herunterladen mehrerer Bilder beschleunigt.",
                "p7": "Wir haben die Benutzeroberfläche verbessert – Sie können jetzt das Design ändern."
            },
            "el": {
                "p1": "Δουλεύουμε σκληρά για να το κάνουμε την καλύτερη επέκταση λήψης εικόνας. Εάν είστε ικανοποιημένοι με την επέκταση μας",
                "p2": "παρακαλούμε σκεφτείτε",
                "p3": "να αξιολογήσετε την επέκταση.",
                "p4": "τι νέο υπάρχει",
                "p5": "Βελτιώσαμε και διορθώσαμε κάποια σφάλματα στον μηχανισμό σύλληψης εικόνας - τώρα λειτουργεί λίγο πιο γρήγορα.",
                "p6": "Βελτιώσαμε τον μηχανισμό λήψης, τα αιτήματα λήψης αποστέλλονται τώρα παράλληλα, επιταχύνοντας τη λήψη πολλαπλών εικόνων.",
                "p7": "Κάναμε βελτιώσεις στη διεπαφή χρήστη - τώρα μπορείτε να αλλάξετε το θέμα."
            },
            "es": {
                "p1": "Trabajamos duro para hacer que sea la mejor extensión para descargar imágenes. Si estás contento con nuestra extensión",
                "p2": "por favor considera",
                "p3": "calificar la extensión.",
                "p4": "qué hay de nuevo",
                "p5": "Hemos mejorado y corregido algunos errores en el mecanismo de captura de imágenes - ahora funciona un poco más rápido.",
                "p6": "Hemos mejorado el mecanismo de descarga, ahora las solicitudes de descarga se envían en paralelo, acelerando la descarga de múltiples imágenes.",
                "p7": "Hemos mejorado la interfaz de usuario - ahora puedes cambiar el tema."
            },
            "et": {
                "p1": "Me teeme kõvasti tööd, et teha sellest parim pildi allalaadimise laiendus. Kui olete meie laiendusega rahul",
                "p2": "palun kaaluda",
                "p3": "laienduse hindamist.",
                "p4": "Mis on uut",
                "p5": "Me parandasime ja parandasime mõned vead pildi jäädvustamise mehhanismis - nüüd töötab see natuke kiiremini.",
                "p6": "Parandasime allalaadimise mehhanismi, allalaadimispäringud saadetakse nüüd paralleelselt, kiirendades mitme pildi allalaadimist.",
                "p7": "Tegime kasutajaliidese täiustusi - nüüd saate muuta teemat."
            },
            "fa": {
                "p1": "ما سخت کار می‌کنیم تا این بهترین افزونه دانلود تصویر باشد. اگر از افزونه ما راضی هستید",
                "p2": "لطفا در نظر بگیرید",
                "p3": "امتیاز دادن به افزونه.",
                "p4": "چه خبر جدیدی است",
                "p5": "ما برخی از اشکالات مکانیزم ضبط تصویر را بهبود بخشیدیم و آن اکنون کمی سریعتر کار می‌کند.",
                "p6": "ما مکانیزم دانلود را بهبود بخشیدیم، درخواست‌های دانلود اکنون به صورت موازی ارسال می‌شوند و دانلود چندین تصویر را تسریع می‌کنند.",
                "p7": "ما به رابط کاربری بهبودهایی ایجاد کردیم - اکنون می‌توانید تم را تغییر دهید."
            },
            "fi": {
                "p1": "Teemme kovasti töitä tehdäksemme siitä parhaan kuvan latauslaajennuksen. Jos olet tyytyväinen laajennukseemme",
                "p2": "ota huomioon",
                "p3": "arvioida laajennus.",
                "p4": "mitä uutta",
                "p5": "Olemme parantaneet ja korjanneet joitain virheitä kuvan tallennusmekanismissa - se toimii nyt hieman nopeammin.",
                "p6": "Olemme parantaneet latausmekanismia, latauspyynnöt lähetetään nyt rinnakkain, mikä nopeuttaa useiden kuvien lataamista.",
                "p7": "Olemme tehneet parannuksia käyttöliittymään - voit nyt vaihtaa teeman."
            },
            "fil": {
                "p1": "Mahigpit kaming nagtatrabaho upang gawing pinakamahusay na extension para sa pag-download ng mga imahe. Kung masaya ka sa aming extension",
                "p2": "pakis consider",
                "p3": "ang pagbibigay ng rating sa extension.",
                "p4": "ano ang bago",
                "p5": "Pinahusay namin at inayos ang ilang mga bug sa mekanismo ng pagkuhanan ng imahe - ngayon ay medyo mas mabilis na ito.",
                "p6": "Pinahusay namin ang mekanismo ng pag-download, ang mga request para sa pag-download ay ipinapadala na ngayon nang sabay-sabay, na nagpapabilis sa pag-download ng maraming imahe.",
                "p7": "Nagdagdag kami ng mga pagpapabuti sa interface ng gumagamit - maaari mo na ngayong baguhin ang tema."
            },
            "fr": {
                "p1": "Nous travaillons dur pour en faire la meilleure extension de téléchargement d'images. Si vous êtes satisfait de notre extension",
                "p2": "veuillez envisager",
                "p3": "d'évaluer l'extension.",
                "p4": "quelles sont les nouveautés",
                "p5": "Nous avons amélioré et corrigé certains bugs dans le mécanisme de capture d'images - il fonctionne maintenant un peu plus vite.",
                "p6": "Nous avons amélioré le mécanisme de téléchargement, les demandes de téléchargement sont désormais envoyées en parallèle, ce qui accélère le téléchargement de plusieurs images.",
                "p7": "Nous avons amélioré l'interface utilisateur - vous pouvez désormais changer de thème."
            },
            "gu": {
                "p1": "અમે તેને શ્રેષ્ઠ છબી ડાઉનલોડ એક્સટેંશન બનાવવામાં જોરદાર મહેનત કરી રહ્યા છીએ. જો તમે અમારી એક્સટેંશનથી ખુશ છો",
                "p2": "કૃપા કરીને વિચાર કરો",
                "p3": "એક્સટેંશને રેટિંગ આપો.",
                "p4": "શું નવું છે",
                "p5": "અમે છબી પકડી મિકેનિઝમમાં કેટલાક બગ્સને સુધાર્યા છે - હવે તે થોડી વધુ ઝડપી કામ કરે છે.",
                "p6": "અમે ડાઉનલોડ મિકેનિઝમમાં સુધારો કર્યો છે, ડાઉનલોડ માટેની વિનંતીઓ હવે સમકક્ષમાં મોકલવામાં આવે છે, જેના પરિણામે મલ્ટી-ઇમેજ ડાઉનલોડ ઝડપથી થાય છે.",
                "p7": "અમે વપરાશકર્તા ઇન્ટરફેસમાં સુધારાઓ કર્યા છે - હવે તમે થીમ બદલી શકો છો."
            },
                "he": {
                    "p1": "אנו עובדים קשה כדי להפוך אותה להרחבת הורדת התמונות הטובה ביותר. אם אתה מרוצה מההרחבה שלנו",
                    "p2": "אנא שקול",
                    "p3": "לדרג את ההרחבה.",
                    "p4": "מה חדש",
                    "p5": "שיפרנו ותיקנו כמה באגים במנגנון לכידת התמונות - הוא פועל עכשיו קצת יותר מהר.",
                    "p6": "שיפרנו את מנגנון ההורדה, בקשות להורדות נשלחות כעת במקביל, מה שמאיץ את הורדת התמונות מרובות.",
                    "p7": "שיפרנו את הממשק המשתמש - עכשיו תוכל לשנות את הנושא."
                },
                "hi": {
                    "p1": "हम इसे सबसे बेहतरीन इमेज डाउनलोड एक्सटेंशन बनाने के लिए कड़ी मेहनत करते हैं। अगर आप हमारे एक्सटेंशन से खुश हैं",
                    "p2": "कृपया विचार करें",
                    "p3": "एक्सटेंशन को रेट करने का।",
                    "p4": "क्या नया है",
                    "p5": "हमने इमेज कैप्चर मैकेनिज्म में कुछ बग्स को सुधार किया है - अब यह थोड़ा तेज़ काम करता है।",
                    "p6": "हमने डाउनलोड मैकेनिज्म में सुधार किया है, डाउनलोड के लिए अनुरोध अब समानांतर में भेजे जाते हैं, जिससे एक साथ कई इमेज डाउनलोड तेज़ होते हैं।",
                    "p7": "हमने यूज़र इंटरफेस में सुधार किया है - अब आप थीम बदल सकते हैं।"
                },
                "hr": {
                    "p1": "Naporno radimo kako bismo ovo učinili najboljim proširenjem za preuzimanje slika. Ako ste zadovoljni našim proširenjem",
                    "p2": "molimo vas da razmislite",
                    "p3": "o ocjeni proširenja.",
                    "p4": "Što je novo",
                    "p5": "Poboljšali smo i popravili neke greške u mehanizmu za hvatanje slika - sada radi malo brže.",
                    "p6": "Poboljšali smo mehanizam za preuzimanje, zahtjevi za preuzimanje sada se šalju paralelno, što ubrzava preuzimanje više slika.",
                    "p7": "Napravili smo poboljšanja korisničkog sučelja - sada možete promijeniti temu."
                },
                "hu": {
                    "p1": "Keményen dolgozunk azon, hogy a legjobb képfeltöltő bővítmény legyen. Ha elégedett vagy a bővítményünkkel",
                    "p2": "kérjük fontolja meg",
                    "p3": "a bővítmény értékelését.",
                    "p4": "Mi újság",
                    "p5": "Javítottunk és kijavítottunk néhány hibát a képfelvételi mechanizmusban - most egy kicsit gyorsabban működik.",
                    "p6": "Fejlesztettük a letöltési mechanizmust, a letöltési kérelmek most párhuzamosan kerülnek elküldésre, ami felgyorsítja a több kép letöltését.",
                    "p7": "Fejlesztettük a felhasználói felületet - most már változtathatsz a témán."
                },
                "id": {
                    "p1": "Kami bekerja keras untuk menjadikannya ekstensi unduhan gambar terbaik. Jika Anda puas dengan ekstensi kami",
                    "p2": "harap pertimbangkan",
                    "p3": "memberi rating pada ekstensi.",
                    "p4": "apa yang baru",
                    "p5": "Kami telah meningkatkan dan memperbaiki beberapa bug dalam mekanisme tangkapan gambar - sekarang sedikit lebih cepat.",
                    "p6": "Kami telah meningkatkan mekanisme unduhan, permintaan unduhan sekarang dikirim secara paralel, mempercepat unduhan gambar secara bersamaan.",
                    "p7": "Kami telah melakukan perbaikan pada antarmuka pengguna - sekarang Anda dapat mengganti tema."
                },
                "it": {
                    "p1": "Lavoriamo duramente per renderlo il miglior estensione di download delle immagini. Se sei soddisfatto della nostra estensione",
                    "p2": "per favore considera",
                    "p3": "di valutare l'estensione.",
                    "p4": "cosa c'è di nuovo",
                    "p5": "Abbiamo migliorato e corretto alcuni bug nel meccanismo di acquisizione delle immagini - ora funziona un po' più velocemente.",
                    "p6": "Abbiamo migliorato il meccanismo di download, le richieste di download vengono ora inviate in parallelo, accelerando il download di più immagini.",
                    "p7": "Abbiamo migliorato l'interfaccia utente - ora puoi cambiare il tema."
                },
                "ja": {
                    "p1": "最良の画像ダウンロード拡張機能を作るために一生懸命働いています。もしあなたが私たちの拡張機能に満足しているなら",
                    "p2": "ぜひ",
                    "p3": "拡張機能の評価をお願いします。",
                    "p4": "新しい機能",
                    "p5": "画像キャプチャメカニズムのバグをいくつか修正し、少し高速に動作するようになりました。",
                    "p6": "ダウンロードメカニズムを改善し、ダウンロードのリクエストは並行して送信されるため、複数の画像のダウンロードが高速化されます。",
                    "p7": "ユーザーインターフェースを改善しました - テーマを変更できるようになりました。"
                },
                "kn": {
                    "p1": "ನಾವು ಅದನ್ನು ಅತ್ಯುತ್ತಮ ಚಿತ್ರ ಡೌನ್ಲೋಡ್ ಎಕ್ಸಟೆನ್ಶನ್ ಮಾಡಲು ಕಠಿಣವಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತೇವೆ. ನೀವು ನಮ್ಮ ಎಕ್ಸಟೆನ್ಶನ್‌ನಿಂದ ಸಂತೋಷವಾದರೆ",
                    "p2": "ದಯವಿಟ್ಟು ಪರಿಗಣಿಸಿ",
                    "p3": "ಎಕ್ಸಟೆನ್ಶನ್‌ಗೆ ರೇಟಿಂಗ್ ನೀಡುವುದು.",
                    "p4": "ಹೊಸ ಏನು ಇದೆ",
                    "p5": "ನಾವು ಚಿತ್ರ ಸೆರೆಹಿಡಿಯುವ ವ್ಯವಸ್ಥೆಯಲ್ಲಿ ಕೆಲವು ಬಗ್ಸ್ ಅನ್ನು ಸುಧಾರಣೆ ಮಾಡಿದ್ದೇವೆ - ಅದು ಈಗ ಸ್ವಲ್ಪ ವೇಗವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ.",
                    "p6": "ನಾವು ಡೌನ್ಲೋಡ್ ವ್ಯವಸ್ಥೆಯನ್ನು ಸುಧಾರಣೆ ಮಾಡಿದ್ದೇವೆ, ಡೌನ್ಲೋಡ್‌ಗಾಗಿ ವಿನಂತಿಗಳನ್ನು ಈಗ ಸಮಾನಾಂತರವಾಗಿ ಕಳುಹಿಸಲಾಗುತ್ತದೆ, ಇದು ಹಲವಾರು ಚಿತ್ರಗಳನ್ನು ಡೌನ್ಲೋಡ್ ಮಾಡುವ ವೇಗವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ.",
                    "p7": "ನಾವು ಬಳಕೆದಾರ ಇಂಟರ್ಫೇಸ್‌ನಲ್ಲಿ ಸುಧಾರಣೆಗಳನ್ನು ಮಾಡಿದ್ದೇವೆ - ನೀವು ಈಗ ಥೀಮ್ ಅನ್ನು ಬದಲಾಗಿಸಬಹುದು."
                },
                "ko": {
                    "p1": "우리는 그것을 최고의 이미지 다운로드 확장 프로그램으로 만들기 위해 열심히 노력합니다. 만약 당신이 우리의 확장 프로그램에 만족한다면",
                    "p2": "부디 고려해 주세요",
                    "p3": "확장 프로그램을 평가해 주세요.",
                    "p4": "새로운 기능",
                    "p5": "우리는 이미지 캡처 메커니즘에서 몇 가지 버그를 수정하고 개선했습니다 - 이제 조금 더 빠르게 작동합니다.",
                    "p6": "우리는 다운로드 메커니즘을 개선했습니다. 다운로드 요청은 이제 병렬로 전송되어 여러 이미지를 더 빨리 다운로드할 수 있습니다.",
                    "p7": "우리는 사용자 인터페이스를 개선했습니다 - 이제 테마를 변경할 수 있습니다."
                },
                    "lt": {
                        "p1": "Mes sunkiai dirbame, kad tai būtų geriausia vaizdų atsisiuntimo plėtinys. Jei esate patenkintas mūsų plėtiniu",
                        "p2": "prašome apsvarstyti",
                        "p3": "įvertinti plėtinį.",
                        "p4": "Kas naujo",
                        "p5": "Mes patobulinome ir ištaisėme kelias klaidas vaizdų užfiksavimo mechanizme - dabar jis veikia šiek tiek greičiau.",
                        "p6": "Mes patobulinome atsisiuntimo mechanizmą, atsisiuntimo užklausos dabar siunčiamos paraleliai, todėl kelių vaizdų atsisiuntimas pagreitėja.",
                        "p7": "Mes patobulinome vartotojo sąsają - dabar galite pakeisti temą."
                    },
                    "lv": {
                        "p1": "Mēs smagi strādājam, lai padarītu to par labāko attēlu lejupielādes paplašinājumu. Ja esat apmierināts ar mūsu paplašinājumu",
                        "p2": "lūdzu, apsveriet",
                        "p3": "novērtēt paplašinājumu.",
                        "p4": "Kas jauns",
                        "p5": "Mēs uzlabojām un izlabojām dažus kļūdas attēlu iegūšanas mehānismā - tas tagad darbojas nedaudz ātrāk.",
                        "p6": "Mēs uzlabojām lejupielādes mehānismu, lejupielādes pieprasījumi tagad tiek sūtīti paralēli, paātrinot vairāku attēlu lejupielādi.",
                        "p7": "Mēs uzlabojām lietotāja interfeisu - tagad varat mainīt tēmu."
                    },
                    "ml": {
                        "p1": "നാം ഇത് മികച്ച ചിത്രം ഡൗൺലോഡ് എക്സ്റ്റെൻഷൻ ആക്കുന്നതിനായി കഠിനമായി ശ്രമിക്കുന്നു. നിങ്ങൾക്ക് ഞങ്ങളുടെ എക്സ്റ്റെൻഷൻ ഇഷ്ടപ്പെട്ടാൽ",
                        "p2": "ദയവായി പരിഗണിക്കുക",
                        "p3": "എക്സ്റ്റെൻഷനെ റേറ്റുചെയ്യുക.",
                        "p4": "പുതിയതെന്താണ്",
                        "p5": "ഞങ്ങൾ ചിത്രീകരണ സംവിധാനത്തിലെ ചില ബഗുകൾ പരിഹരിക്കുകയും മെച്ചപ്പെടുത്തുകയും ചെയ്തിട്ടുണ്ട് - ഇത് ഇപ്പോൾ ചെറുതായി വേഗത്തിൽ പ്രവർത്തിക്കുന്നു.",
                        "p6": "ഞങ്ങൾ ഡൗൺലോഡ് സംവിധാനം മെച്ചപ്പെടുത്തി, ഡൗൺലോഡ് അഭ്യർത്ഥനകൾ ഇപ്പോൾ സമാന്തരമായി അയച്ചുനൽകുന്നു, ഇതിലൂടെ പല ചിത്രങ്ങളും വേഗത്തിൽ ഡൗൺലോഡ് ചെയ്യാം.",
                        "p7": "ഞങ്ങൾ ഉപയോക്തൃ ഇന്റർഫേസിൽ മെച്ചപ്പെടുത്തലുകൾ ചെയ്തിട്ടുണ്ട് - നിങ്ങൾ ഇപ്പോൾ വിഷയത്തെ മാറ്റാം."
                    },
                    "mr": {
                        "p1": "आम्ही ते सर्वोत्तम चित्र डाउनलोड विस्तार बनवण्यासाठी कष्टाने काम करत आहोत. जर तुम्हाला आमच्या विस्ताराबद्दल आनंद झाला असेल",
                        "p2": "कृपया विचार करा",
                        "p3": "विस्तारास रेट करण्याचा.",
                        "p4": "नवीन काय आहे",
                        "p5": "आम्ही चित्र कॅप्चर यांत्रणेमध्ये काही बग्ज सुधारले आहेत - ते आता थोडे वेगाने कार्य करते.",
                        "p6": "आम्ही डाउनलोड यांत्रणेमध्ये सुधारणा केली आहे, डाउनलोडसाठी विनंत्या आता समानांतरमध्ये पाठवल्या जातात, ज्यामुळे एकाच वेळी अनेक चित्रे डाउनलोड होण्याची गती वाढते.",
                        "p7": "आम्ही वापरकर्ता इंटरफेसमध्ये सुधारणा केल्या आहेत - तुम्ही आता थीम बदलू शकता."
                    },
                    "ms": {
                        "p1": "Kami bekerja keras untuk menjadikannya ekstensi unduhan gambar terbaik. Jika Anda puas dengan ekstensi kami",
                        "p2": "silakan pertimbangkan",
                        "p3": "memberikan rating pada ekstensi.",
                        "p4": "apa yang baru",
                        "p5": "Kami telah memperbaiki dan meningkatkan beberapa bug dalam mekanisme pengambilan gambar - sekarang sedikit lebih cepat.",
                        "p6": "Kami telah meningkatkan mekanisme pengunduhan, permintaan pengunduhan sekarang dikirimkan secara paralel, mempercepat pengunduhan banyak gambar.",
                        "p7": "Kami telah melakukan perbaikan pada antarmuka pengguna - sekarang Anda dapat mengubah tema."
                    },
                    "nl": {
                        "p1": "We werken hard om het de beste afbeeldingsdownload-extensie te maken. Als je tevreden bent met onze extensie",
                        "p2": "overweeg dan",
                        "p3": "de extensie te beoordelen.",
                        "p4": "wat is er nieuw",
                        "p5": "We hebben enkele bugs in het afbeelding vastlegmechanisme verbeterd en gecorrigeerd - het werkt nu iets sneller.",
                        "p6": "We hebben het downloadmechanisme verbeterd, downloadverzoeken worden nu parallel verzonden, wat het downloaden van meerdere afbeeldingen versnelt.",
                        "p7": "We hebben de gebruikersinterface verbeterd - je kunt nu het thema wijzigen."
                    },
                    "no": {
                        "p1": "Vi jobber hard for å gjøre det til den beste bilde-nedlastingsutvidelsen. Hvis du er fornøyd med utvidelsen vår",
                        "p2": "vær så snill å vurdere",
                        "p3": "å vurdere utvidelsen.",
                        "p4": "hva er nytt",
                        "p5": "Vi har forbedret og fikset noen feil i bildegjenkjenningsmekanismen - det fungerer litt raskere nå.",
                        "p6": "Vi har forbedret nedlastingsmekanismen, forespørsler om nedlasting sendes nå parallelt, noe som akselererer nedlasting av flere bilder.",
                        "p7": "Vi har forbedret brukergrensesnittet - du kan nå endre tema."
                    },                
                        "pl": {
                            "p1": "Ciężko pracujemy, aby uczynić to najlepszym rozszerzeniem do pobierania obrazów. Jeśli jesteś zadowolony z naszego rozszerzenia",
                            "p2": "proszę rozważ",
                            "p3": "ocenienie rozszerzenia.",
                            "p4": "co nowego",
                            "p5": "Poprawiliśmy i naprawiliśmy kilka błędów w mechanizmie przechwytywania obrazów - teraz działa nieco szybciej.",
                            "p6": "Ulepszyliśmy mechanizm pobierania, teraz żądania pobierania są wysyłane równolegle, co przyspiesza pobieranie wielu obrazów.",
                            "p7": "Ulepszyliśmy interfejs użytkownika - teraz możesz zmienić motyw."
                        },
                        "pt": {
                            "p1": "Trabalhamos duro para torná-lo a melhor extensão de download de imagens. Se você está satisfeito com nossa extensão",
                            "p2": "por favor, considere",
                            "p3": "avaliar a extensão.",
                            "p4": "o que há de novo",
                            "p5": "Melhoramos e corrigimos alguns bugs no mecanismo de captura de imagens - agora ele funciona um pouco mais rápido.",
                            "p6": "Melhoramos o mecanismo de download, os pedidos de download agora são enviados em paralelo, acelerando o download de várias imagens.",
                            "p7": "Melhoramos a interface do usuário - agora você pode mudar o tema."
                        },
                        "ro": {
                            "p1": "Lucrăm din greu pentru a-l face cea mai bună extensie de descărcare a imaginilor. Dacă sunteți mulțumit de extensia noastră",
                            "p2": "vă rugăm să luați în considerare",
                            "p3": "evaluarea extensiei.",
                            "p4": "ce este nou",
                            "p5": "Am îmbunătățit și corectat câteva erori în mecanismul de capturare a imaginilor - acum funcționează puțin mai rapid.",
                            "p6": "Am îmbunătățit mecanismul de descărcare, cererile de descărcare sunt acum trimise paralel, accelerând descărcarea mai multor imagini.",
                            "p7": "Am îmbunătățit interfața cu utilizatorul - acum poți schimba tema."
                        },
                        "ru": {
                            "p1": "Мы усердно работаем, чтобы сделать это лучшим расширением для загрузки изображений. Если вам нравится наше расширение",
                            "p2": "пожалуйста, подумайте о",
                            "p3": "оценке расширения.",
                            "p4": "что нового",
                            "p5": "Мы улучшили и исправили несколько ошибок в механизме захвата изображений - теперь он работает немного быстрее.",
                            "p6": "Мы улучшили механизм загрузки, запросы на загрузку теперь отправляются параллельно, что ускоряет загрузку нескольких изображений.",
                            "p7": "Мы улучшили интерфейс пользователя - теперь вы можете сменить тему."
                        },
                        "sk": {
                            "p1": "Tvrdě pracujeme na tom, aby sme ho urobili najlepším rozšírením na stiahnutie obrázkov. Ak ste spokojní s naším rozšírením",
                            "p2": "prosím zvážte",
                            "p3": "ohodnotenie rozšírenia.",
                            "p4": "čo je nové",
                            "p5": "Vylepšili sme a opravili niektoré chyby v mechanizme zachytávania obrázkov - teraz funguje o niečo rýchlejšie.",
                            "p6": "Vylepšili sme mechanizmus sťahovania, požiadavky na sťahovanie sú teraz zasielané paralelne, čo urýchľuje sťahovanie viacerých obrázkov.",
                            "p7": "Vylepšili sme používateľské rozhranie - teraz si môžete zmeniť tému."
                        },
                        "sl": {
                            "p1": "Trdo delamo, da bo to najboljši razširitev za prenos slik. Če ste zadovoljni z našo razširitvijo",
                            "p2": "prosimo, razmislite o",
                            "p3": "ocenjevanju razširitve.",
                            "p4": "Kaj je novega",
                            "p5": "Izboljšali smo in popravili nekaj napak v mehanizmu zajema slik - zdaj deluje nekoliko hitreje.",
                            "p6": "Izboljšali smo mehanizem prenosa, zahteve za prenos so zdaj poslane hkrati, kar pospeši prenos več slik.",
                            "p7": "Izboljšali smo uporabniški vmesnik - zdaj lahko spremenite temo."
                        },
                        "sr": {
                            "p1": "Naporno radimo kako bismo ovo učinili najboljim proširenjem za preuzimanje slika. Ako ste zadovoljni našim proširenjem",
                            "p2": "molimo vas da razmislite",
                            "p3": "o oceni proširenja.",
                            "p4": "Šta je novo",
                            "p5": "Poboljšali smo i popravili nekoliko grešaka u mehanizmu za hvatanje slika - sada radi malo brže.",
                            "p6": "Poboljšali smo mehanizam za preuzimanje, zahtevi za preuzimanje sada se šalju paralelno, što ubrzava preuzimanje više slika.",
                            "p7": "Napravili smo poboljšanja korisničkog interfejsa - sada možete promeniti temu."
                        },
                        "sv": {
                            "p1": "Vi arbetar hårt för att göra det till den bästa bildnedladdningstillägget. Om du är nöjd med vårt tillägg",
                            "p2": "överväg vänligen",
                            "p3": "att ge tillägget ett betyg.",
                            "p4": "vad är nytt",
                            "p5": "Vi har förbättrat och åtgärdat några buggar i bildfångstmekanismen - den fungerar nu lite snabbare.",
                            "p6": "Vi har förbättrat nedladdningsmekanismen, nedladdningsförfrågningar skickas nu parallellt, vilket påskyndar nedladdningen av flera bilder.",
                            "p7": "Vi har förbättrat användargränssnittet - nu kan du ändra tema."
                        },                    
                            "sw": {
                                "p1": "Tunafanya kazi kwa bidii ili iwe kuongeza bora ya upakuaji picha. Ikiwa unafurahi na kuongeza yetu",
                                "p2": "tafadhali fikiria",
                                "p3": "kudhibitisha kuongeza.",
                                "p4": "nini kipya",
                                "p5": "Tumeimarisha na kurekebisha baadhi ya makosa kwenye mfumo wa kukamata picha - sasa inafanya kazi kidogo haraka.",
                                "p6": "Tumeimarisha mfumo wa kupakua, maombi ya kupakua sasa yanatumwa kwa usawa, kasi ya kupakua picha nyingi inaboreshwa.",
                                "p7": "Tumeimarisha kiolesura cha mtumiaji - sasa unaweza kubadilisha mandhari."
                            },
                            "ta": {
                                "p1": "நாம் இதை சிறந்த பட இறக்கம் விரிவாக்கமாக மாற்ற கடினமாக வேலை செய்கிறோம். நீங்கள் எங்கள் விரிவாக்கத்துடன் மகிழ்ச்சியுடன் இருந்தால்",
                                "p2": "தயவுசெய்து பரிசீலிக்கவும்",
                                "p3": "விரிவாக்கத்தை மதிப்பிடுதல்.",
                                "p4": "புதியது என்ன",
                                "p5": "நாங்கள் படம் பிடிக்கும் முறைமையில் சில பிழைகளை திருத்தி மேம்படுத்தியுள்ளோம் - அது தற்போது கொஞ்சம் வேகமாக செயல்படுகிறது.",
                                "p6": "நாங்கள் பதிவிறக்கும் முறைமையை மேம்படுத்தியுள்ளோம், பதிவிறக்கம் கோரிக்கைகள் இப்போது சமநிலை முறைவாக அனுப்பப்படுகின்றன, பல படங்களை விரைவில் பதிவிறக்கம் செய்ய உதவுகிறது.",
                                "p7": "நாங்கள் பயனர் இடைமுகத்தை மேம்படுத்தியுள்ளோம் - இப்போது நீங்கள் தீமை மாற்ற முடியும்."
                            },
                            "te": {
                                "p1": "మేము దీన్ని బెస్ట్ ఇమేజ్ డౌన్‌లోడ్ ఎక్స్‌టెన్షన్‌గా మార్చడానికి కఠినంగా పని చేస్తున్నాం. మీరు మా ఎక్స్‌టెన్షన్‌తో సంతోషంగా ఉంటే",
                                "p2": "దయచేసి పరిగణనలోకి తీసుకోండి",
                                "p3": "ఎక్స్‌టెన్షన్‌ను రేటింగ్ చేయండి.",
                                "p4": "కొత్తది ఏమిటి",
                                "p5": "మీడియా క్యాప్చర్ మెకానిజంలో కొన్ని బగ్‌లను మెరుగుపరిచాము - ఇప్పుడు కొంచెం వేగంగా పని చేస్తోంది.",
                                "p6": "డౌన్‌లోడ్ మెకానిజాన్ని మెరుగుపరిచాము, డౌన్‌లోడ్స్‌కి అభ్యర్థనలు ఇప్పుడు సమాంతరంగా పంపబడతాయి, దీంతో బహుళ చిత్రాలను త్వరగా డౌన్‌లోడ్ చేయవచ్చు.",
                                "p7": "యూజర్ ఇంటర్ఫేస్‌లో మెరుగులు చేసింది - ఇప్పుడు మీరు థీమ్‌ను మార్చవచ్చు."
                            },
                            "th": {
                                "p1": "เราทำงานหนักเพื่อทำให้มันเป็นส่วนขยายการดาวน์โหลดภาพที่ดีที่สุด หากคุณพอใจกับส่วนขยายของเรา",
                                "p2": "โปรดพิจารณา",
                                "p3": "ให้คะแนนส่วนขยายนี้.",
                                "p4": "มีอะไรใหม่",
                                "p5": "เราได้ปรับปรุงและแก้ไขข้อผิดพลาดบางประการในกลไกการจับภาพ - ตอนนี้ทำงานเร็วขึ้นเล็กน้อย.",
                                "p6": "เราได้ปรับปรุงกลไกการดาวน์โหลด คำขอดาวน์โหลดตอนนี้ถูกส่งพร้อมกัน ซึ่งทำให้การดาวน์โหลดหลายๆ ภาพเร็วขึ้น.",
                                "p7": "เราได้ปรับปรุงอินเทอร์เฟซผู้ใช้ - ตอนนี้คุณสามารถเปลี่ยนธีมได้."
                            },
                            "tr": {
                                "p1": "Bunu en iyi görüntü indirme uzantısı yapmak için çok çalışıyoruz. Uzantımızdan memnun kaldıysanız",
                                "p2": "lütfen düşünün",
                                "p3": "uzantıyı puanlamak.",
                                "p4": "yeni ne var",
                                "p5": "Görüntü yakalama mekanizmasındaki bazı hataları düzelttik ve geliştirdik - şimdi biraz daha hızlı çalışıyor.",
                                "p6": "İndirme mekanizmasını geliştirdik, indirme istekleri artık paralel olarak gönderiliyor, bu da birden fazla görüntünün hızlı bir şekilde indirilmesini sağlıyor.",
                                "p7": "Kullanıcı arayüzünde iyileştirmeler yaptık - artık temayı değiştirebilirsiniz."
                            },
                            "uk": {
                                "p1": "Ми працюємо над тим, щоб зробити це найкращим розширенням для завантаження зображень. Якщо вам подобається наше розширення",
                                "p2": "будь ласка, розгляньте",
                                "p3": "оцінку розширення.",
                                "p4": "що нового",
                                "p5": "Ми покращили та виправили деякі помилки в механізмі захоплення зображень - тепер він працює трохи швидше.",
                                "p6": "Ми покращили механізм завантаження, запити на завантаження тепер надсилаються паралельно, що прискорює завантаження кількох зображень.",
                                "p7": "Ми покращили інтерфейс користувача - тепер ви можете змінювати тему."
                            },
                            "vi": {
                                "p1": "Chúng tôi làm việc chăm chỉ để làm cho nó trở thành phần mở rộng tải xuống hình ảnh tốt nhất. Nếu bạn hài lòng với phần mở rộng của chúng tôi",
                                "p2": "vui lòng xem xét",
                                "p3": "đánh giá phần mở rộng.",
                                "p4": "có gì mới",
                                "p5": "Chúng tôi đã cải thiện và sửa một số lỗi trong cơ chế bắt hình ảnh - giờ nó hoạt động nhanh hơn một chút.",
                                "p6": "Chúng tôi đã cải thiện cơ chế tải xuống, các yêu cầu tải xuống bây giờ được gửi song song, giúp tăng tốc tải xuống nhiều hình ảnh.",
                                "p7": "Chúng tôi đã cải thiện giao diện người dùng - bây giờ bạn có thể thay đổi chủ đề."
                            },
                            "zh_CN": {
                                "p1": "我们努力使其成为最佳图片下载扩展。如果您对我们的扩展感到满意",
                                "p2": "请考虑",
                                "p3": "对扩展进行评分。",
                                "p4": "有什么新内容",
                                "p5": "我们改进并修复了图像捕获机制中的一些错误——现在它运行得更快了。",
                                "p6": "我们改进了下载机制，下载请求现在并行发送，加速了多图像下载。",
                                "p7": "我们对用户界面进行了改进——现在您可以更改主题。"
                            },
                            "zh_TW": {
                                "p1": "我們努力使其成為最佳圖片下載擴展。如果您對我們的擴展感到滿意",
                                "p2": "請考慮",
                                "p3": "對擴展進行評分。",
                                "p4": "有什麼新內容",
                                "p5": "我們改進並修復了圖像捕獲機制中的一些錯誤——現在它運行得更快了。",
                                "p6": "我們改進了下載機制，下載請求現在並行發送，加速了多圖片下載。",
                                "p7": "我們對用戶界面進行了改進——現在您可以更改主題。"
                            }
                        }



const { browser, lang } = useParams();
let url = "https://chromewebstore.google.com/detail/image-downloader-save-all/fpelahbljekknahkcaegamhcndkfpfnc";
if (browser == "edge") {
    url = "https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl";
} else if (browser == "firefox") {
    url = "https://addons.mozilla.org/en-US/firefox/addon/image-downloader-for-firefox/";
} else if (browser == "opera") {
    url = "opera"
}
let header = headers[lang];
let p1 = text[lang]['p1'];
let p2 = text[lang]['p2'];
let p3 = text[lang]['p3'];
let p4 = text[lang]['p4'];
let p5 = text[lang]['p5'];
let p6 = text[lang]['p6'];
let p7 = text[lang]['p7'];
let enLink = "../image_downloader-rate_us/" + browser + "/en";
return (
    <>

        <div style={{ backgroundImage: "URL(/../assets/meercat-download-images.jpeg)", width: "1024px", height: "1024px" }}>
            <div style={{ color: "white", margin: "48px" }}>
                <br />
                <h2>
                    <div style={{ backgroundColor: "rgb(0,0,0,0.6)", paddingLeft: "8px", paddingBottom: "8px" }}><p>
                    <div>
            <NavLink to="/en/contact"><img src="/../assets/email-icon.svg" style={{ float: "right", width: "48px", margin: "16px" }} /></NavLink>
            {lang == 'en' ? "" : <NavLink to={enLink}><img src="/../assets/en.svg" style={{ float: "right", width: "44px", margin: "24px 12px" }} /></NavLink>}
            <h2 style={{ color: "white", padding: "4px" }}>{header}</h2>
        </div>
                        <h2>{p4}</h2>
                        <ul>
                            <li>
                                {p6}
                            </li><br />
                        </ul></p>{p1} {p2} <a href={url} style={{ padding: "4px", color: "orange" }}>{p3}</a></div>

                </h2>
            </div>
        </div>
    </>
)
}